<template>
  <div>
    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers
      p-datatable-responsive-demo"
      :rows="100"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه المرقبه

          <div style="text-align:left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>

      <!-- :sortable="true" -->
      <Column
        v-for="(col, index) of selectedColumns"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
      >
        <template v-if="col.field == 'userType'" #body="slotProps">
          {{ slotProps.data.userType == 0 ? 'مدير' : 'أكاديميه' }}
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          {{ slotProps.data.dataMsg }}
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expandedRows: [],
      id: null,
      list: [],
      selectedItems: [],
      selectedColumns: [],
      columns: [
        {
          field: 'name',
          header: 'الوصف',
        },
        {
          field: 'typeData',
          header: 'نوع الاكشن',
        },

        {
          field: 'userType',
          header: 'نوع المستخدم',
        },

        {
          field: 'userName',
          header: 'اسم المستخدم',
        },

      
      ],
      loading: true,
    };
  },
  methods: {
    getData() {
      this.$http.get(`logs`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
